import en from './entries/en-US';
import zh from './entries/zh-CN';
import fr from './entries/fr-FR';
import ja from './entries/ja-JP';

const AppLocale = {
  en,
  zh,
  fr,
  ja,
};

export default AppLocale;

export type AppLocaleKey = keyof typeof AppLocale;
