import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from 'emilus/constants/ThemeConstant';
import { AppLocaleKey } from 'lang';

export type AppTheme = {
  navCollapsed: boolean;
  sideNavTheme: string;
  navType: string;
  topNavColor: string;
  headerNavColor: string;
  mobileNav: boolean;
  currentTheme: string;
  currentLocale: AppLocaleKey;
};

export const initialTheme: AppTheme = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  currentLocale: 'en',
};

function saveTheme(theme: AppTheme) {
  const json = JSON.stringify(theme);
  localStorage.setItem('THEME', json);
  return theme;
}

function loadTheme(): AppTheme {
  const json = localStorage.getItem('THEME');
  if (!json) {
    return initialTheme;
  }
  const theme = JSON.parse(json);
  // fix the issue caused by renaming theme to currentTheme
  // it might still be saved as theme on some users' browsers
  theme.currentTheme = theme.currentTheme ?? theme.theme;

  return theme;
}

const themeSlice = createSlice({
  name: 'ui',
  initialState: loadTheme(),
  reducers: {
    updateTheme(oldTheme, a: PayloadAction<Partial<AppTheme>>): AppTheme {
      return saveTheme({ ...oldTheme, ...a.payload });
    },
  },
});

export default themeSlice.reducer;

export const { updateTheme } = themeSlice.actions;

export function toggleCollapsedNav(navCollapsed: boolean) {
  return updateTheme({ navCollapsed });
}

export function onNavStyleChange(sideNavTheme: string) {
  return updateTheme({ sideNavTheme });
}

export function onLocaleChange(currentLocale: AppLocaleKey) {
  return updateTheme({ currentLocale });
}

export function onNavTypeChange(navType: string) {
  return updateTheme({ navType });
}

export function onTopNavColorChange(topNavColor: string) {
  return updateTheme({ topNavColor });
}

export function onHeaderNavColorChange(headerNavColor: string) {
  return updateTheme({ headerNavColor });
}

export function onMobileNavToggle(mobileNav: boolean) {
  return updateTheme({ mobileNav });
}

export function onSwitchTheme(currentTheme: string) {
  return updateTheme({ currentTheme });
}
