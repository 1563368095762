import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth, LoginForm } from './auth';
import { RootState } from './store';
import { Deal, NewDeal, PasswordChangeParams, Status, Target, User } from './types';

const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth?.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['User', 'Deal', 'Target'],
  endpoints: ({ mutation, query }) => ({
    login: mutation<Auth, LoginForm>({
      query: (body) => ({ url: 'auth/login', body, method: 'POST' }),
      invalidatesTags: ['User', 'Deal', 'Target'],
    }),
    loginAzure: mutation<Auth, { token: string }>({
      query: (body) => ({ url: 'auth/azure', body, method: 'POST' }),
      invalidatesTags: ['User', 'Deal', 'Target'],
    }),
    logout: mutation<undefined, void>({
      queryFn: () => ({ data: undefined }),
      invalidatesTags: ['User', 'Deal', 'Target'],
    }),
    listDeals: query<Deal[], void>({
      query: () => 'deals',
      providesTags: ['Deal'],
    }),
    createDeal: mutation<Deal, NewDeal>({
      query: (body) => ({ url: 'deals', body, method: 'POST' }),
      invalidatesTags: (result) => (result ? ['Deal'] : []),
    }),
    deleteDeal: mutation<void, number>({
      query: (id) => ({ url: `deals/${id}`, method: 'DELETE' }),
      invalidatesTags: (result) => (result ? ['Deal'] : []),
    }),
    setDealStatus: mutation<Deal, [number, Status]>({
      query: ([id, status]) => ({
        url: `deals/${status}`,
        body: { ids: [id] },
        method: 'POST',
      }),
      invalidatesTags: (result) => (result ? ['Deal'] : []),
    }),
    getTarget: query<Target, void>({
      query: () => 'target',
      providesTags: ['Target'],
    }),
    setTarget: mutation<Target, Partial<Target>>({
      query: (body) => ({ url: 'target', body, method: 'POST' }),
      invalidatesTags: (result) => (result ? ['Target'] : []),
    }),
    updateProfile: mutation<User, Partial<User>>({
      query: (body) => ({ url: 'account/profile', body, method: 'POST' }),
    }),
    changePassword: mutation<void, PasswordChangeParams>({
      query: (body) => ({ url: 'account/password', body, method: 'POST' }),
    }),
  }),
});

export default api;

export const {
  useLoginMutation,
  useLoginAzureMutation,
  useLogoutMutation,
  useListDealsQuery,
  useCreateDealMutation,
  useGetTargetQuery,
  useSetTargetMutation,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useDeleteDealMutation,
  useSetDealStatusMutation,
} = api;
