import { Configuration } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { message } from 'antd';
import { useCallback, useMemo } from 'react';

export const msalConfig: Configuration = {
  auth: {
    clientId: 'f4969f79-b174-4b2e-a018-883bd31a3829',
    authority: 'https://login.microsoftonline.com/zoracom.com',
    redirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export function useMsalAuth() {
  const { instance, accounts, inProgress } = useMsal();
  const account = accounts[0];
  const isAuthenticated = useIsAuthenticated();

  const onError = useCallback((e: any) => {
    console.error(e);
    message.error(e.message, 5000);
  }, []);

  const login = useCallback(
    () => instance.loginPopup(loginRequest).catch(onError),
    [instance, onError]
  );

  const logout = useCallback(
    () => instance.logoutRedirect({ onRedirectNavigate: () => false }).catch(onError),
    [instance, onError]
  );

  const acquireToken = useCallback(async () => {
    const request = { ...loginRequest, account };
    try {
      return await instance.acquireTokenSilent(request);
    } catch (e) {
      return await instance.acquireTokenPopup(request);
    }
  }, [account, instance]);

  // memoize the whole result so it can be used as a hook dependency
  return useMemo(
    () => ({
      account,
      instance,
      inProgress,
      isAuthenticated,
      acquireToken,
      login,
      logout,
    }),
    [account, acquireToken, inProgress, instance, isAuthenticated, login, logout]
  );
}
