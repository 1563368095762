import { useSelector } from 'react-redux';
import { RootState } from 'data/store';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

export function useTheme() {
  return useSelector((state: RootState) => state.theme);
}

export function useIsMobile() {
  return !useBreakpoint().lg;
}