import antdZhCn from 'antd/es/locale/zh_CN';

const zh = {
  antd: antdZhCn,
  locale: 'zh',
  messages: {
    home: '主页',

    'sidenav.dashboard': '仪表板',
    'sidenav.dashboard.default': '默认',
    'sidenav.dashboard.analytic': '分析页',
    'sidenav.dashboard.sales': '销售',

    'sidenav.apps': '应用',
    'sidenav.apps.mail': '邮件',
    'sidenav.apps.chat': '聊天室',
    'sidenav.apps.calendar': '日历',
    'sidenav.apps.project': '项目',
    'sidenav.apps.project.list': '列表',
    'sidenav.apps.project.scrumboard': '看板',
    'sidenav.apps.ecommerce': '电子商务',
    'sidenav.apps.ecommerce.product': '产品',
    'sidenav.apps.ecommerce.editProduct': '编辑产品',
    'sidenav.apps.ecommerce.addProduct': '添加产品',
    'sidenav.apps.ecommerce.productList': '产品列表',
    'sidenav.apps.ecommerce.orders': '注文',

    'sidenav.components': '组件',
    'sidenav.components.general': '通用',
    'sidenav.components.general.button': '按钮',
    'sidenav.components.general.icon': '图标',
    'sidenav.components.general.typography': '排版',
    'sidenav.components.layout': '布局',
    'sidenav.components.layout.grid': '栅格',
    'sidenav.components.layout.layout': '布局',

    'sidenav.components.navigation': '导航',
    'sidenav.components.navigation.affix': '固钉',
    'sidenav.components.navigation.breadcrumb': '面包屑',
    'sidenav.components.navigation.dropdown': '下拉菜单',
    'sidenav.components.navigation.menu': '导航菜单',
    'sidenav.components.navigation.pagination': '分页',
    'sidenav.components.navigation.pageHeader': '页头',
    'sidenav.components.navigation.steps': '步骤条',

    'sidenav.components.dataEntry': '数据录入',
    'sidenav.components.dataEntry.autoComplete': '自动完成',
    'sidenav.components.dataEntry.checkbox': '多选框',
    'sidenav.components.dataEntry.cascader': '级联选择',
    'sidenav.components.dataEntry.datePicker': '日期选择框',
    'sidenav.components.dataEntry.form': '表单',
    'sidenav.components.dataEntry.inputNumber': '数字输入框',
    'sidenav.components.dataEntry.input': '输入框',
    'sidenav.components.dataEntry.mentions': '提及',
    'sidenav.components.dataEntry.rate': '评分',
    'sidenav.components.dataEntry.radio': '单选框',
    'sidenav.components.dataEntry.switch': '开关',
    'sidenav.components.dataEntry.slider': '滑动输入条',
    'sidenav.components.dataEntry.select': '选择器',
    'sidenav.components.dataEntry.treeSelect': '树选择',
    'sidenav.components.dataEntry.transfer': '穿梭框',
    'sidenav.components.dataEntry.timePicker': '时间选择框',
    'sidenav.components.dataEntry.upload': '上传',

    'sidenav.components.dataDisplay': '数据展示',
    'sidenav.components.dataDisplay.avatar': '头像',
    'sidenav.components.dataDisplay.badge': '徽标数',
    'sidenav.components.dataDisplay.comment': '评论',
    'sidenav.components.dataDisplay.collapse': '折叠面板',
    'sidenav.components.dataDisplay.carousel': '走马灯',
    'sidenav.components.dataDisplay.card': '卡片',
    'sidenav.components.dataDisplay.calendar': '日历',
    'sidenav.components.dataDisplay.descriptions': '描述列表',
    'sidenav.components.dataDisplay.empty': '空状态',
    'sidenav.components.dataDisplay.list': '列表',
    'sidenav.components.dataDisplay.popover': '气泡卡片',
    'sidenav.components.dataDisplay.statistic': '统计数值',
    'sidenav.components.dataDisplay.tree': '树形控件',
    'sidenav.components.dataDisplay.tooltip': '文字提示',
    'sidenav.components.dataDisplay.timeline': '时间轴',
    'sidenav.components.dataDisplay.tag': '标签',
    'sidenav.components.dataDisplay.tabs': '标签页',
    'sidenav.components.dataDisplay.table': '表格',

    'sidenav.components.feedback': '反馈',
    'sidenav.components.feedback.alert': '警告提示',
    'sidenav.components.feedback.drawer': '抽屉',
    'sidenav.components.feedback.modal': '对话框',
    'sidenav.components.feedback.message': '全局提示',
    'sidenav.components.feedback.notification': '通知提醒框',
    'sidenav.components.feedback.progress': '进度条',
    'sidenav.components.feedback.popconfirm': '气泡确认框',
    'sidenav.components.feedback.result': '结果',
    'sidenav.components.feedback.spin': '加载中',
    'sidenav.components.feedback.skeleton': '骨架屏',

    'sidenav.components.other': '其他',
    'sidenav.components.other.anchor': '锚点',
    'sidenav.components.other.backtop': '回到顶部',
    'sidenav.components.other.configProvider': '全局化配置',
    'sidenav.components.other.divider': '分割线',

    'sidenav.charts': '图表',
    'sidenav.charts.apex': 'Apex Charts',
    'sidenav.charts.chartjs': 'ChartJs',

    'sidenav.maps': '地图',
    'sidenav.maps.google': 'Google Maps',
    'sidenav.maps.simple': 'Simple Maps',

    'sidenav.pages': '页面',
    'sidenav.pages.profile': '个人资料',
    'sidenav.pages.invoice': '单据',
    'sidenav.pages.pricing': '价钱',
    'sidenav.pages.faq': '常问问题',
    'sidenav.pages.setting': '设置',
    'sidenav.pages.userlist': '用户清单',

    'sidenav.authentication': '认证',
    'sidenav.authentication.login.1': '登录页 1',
    'sidenav.authentication.login.2': '登录页 2',
    'sidenav.authentication.register.1': '注册页 1',
    'sidenav.authentication.register.2': '注册页 2',
    'sidenav.authentication.forgetPassword': '忘记密码',

    'sidenav.errors': '错误',
    'sidenav.errors.error.1': '错误页 1',
    'sidenav.errors.error.2': '错误页 2',

    'sidenav.docs': '文件',
    'sidenav.docs.documentation': '文献资料',
    'sidenav.docs.changelog': '变更日志',
  },
};
export default zh;
