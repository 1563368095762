import antdEnUS from 'antd/es/locale/en_US';

const en = {
  antd: antdEnUS,
  locale: 'en-US',
  messages: {
    Home: 'Home',
    Overview: 'Overview',
    Activities: 'Activities',
    Calendar: 'Calendar',
    Projects: 'Projects',
    Reports: 'Reports',
    Support: 'Support',
    History: 'History',

    'sidenav.apps': 'Apps',
    'sidenav.apps.mail': 'Mail',
    'sidenav.apps.chat': 'Chat',
    'sidenav.apps.project': 'Project',
    'sidenav.apps.project.list': 'List',
    'sidenav.apps.project.scrumboard': 'Scrumboard',
    'sidenav.apps.ecommerce': 'E-Commerce',
    'sidenav.apps.ecommerce.product': 'Product',
    'sidenav.apps.ecommerce.editProduct': 'Edit Product',
    'sidenav.apps.ecommerce.addProduct': 'Add Product',
    'sidenav.apps.ecommerce.productList': 'Product List',
    'sidenav.apps.ecommerce.orders': 'Orders',

    'sidenav.components': 'Components',
    'sidenav.components.general': 'General',
    'sidenav.components.general.button': 'Button',
    'sidenav.components.general.icon': 'Icon',
    'sidenav.components.general.typography': 'Typography',
    'sidenav.components.layout': 'Layout',
    'sidenav.components.layout.grid': 'Grid',
    'sidenav.components.layout.layout': 'Layout',

    'sidenav.components.navigation': 'Navigation',
    'sidenav.components.navigation.affix': 'Affix',
    'sidenav.components.navigation.breadcrumb': 'Breadcrumb',
    'sidenav.components.navigation.dropdown': 'Dropdown',
    'sidenav.components.navigation.menu': 'Menu',
    'sidenav.components.navigation.pagination': 'Pagination',
    'sidenav.components.navigation.pageHeader': 'Page Header',
    'sidenav.components.navigation.steps': 'Steps',

    'sidenav.components.dataEntry': 'Data Entry',
    'sidenav.components.dataEntry.autoComplete': 'Auto Complete',
    'sidenav.components.dataEntry.checkbox': 'Checkbox',
    'sidenav.components.dataEntry.cascader': 'Cascader',
    'sidenav.components.dataEntry.datePicker': 'Date Picker',
    'sidenav.components.dataEntry.form': 'Form',
    'sidenav.components.dataEntry.inputNumber': 'Input Number',
    'sidenav.components.dataEntry.input': 'Input',
    'sidenav.components.dataEntry.mentions': 'Mentions',
    'sidenav.components.dataEntry.rate': 'Rate',
    'sidenav.components.dataEntry.radio': 'Radio',
    'sidenav.components.dataEntry.switch': 'Switch',
    'sidenav.components.dataEntry.slider': 'Slider',
    'sidenav.components.dataEntry.select': 'Select',
    'sidenav.components.dataEntry.treeSelect': 'Tree Select',
    'sidenav.components.dataEntry.transfer': 'Transfer',
    'sidenav.components.dataEntry.timePicker': 'Time Picker',
    'sidenav.components.dataEntry.upload': 'Upload',

    'sidenav.components.dataDisplay': 'Data Display',
    'sidenav.components.dataDisplay.avatar': 'Avatar',
    'sidenav.components.dataDisplay.badge': 'Badge',
    'sidenav.components.dataDisplay.comment': 'Comment',
    'sidenav.components.dataDisplay.collapse': 'Collapse',
    'sidenav.components.dataDisplay.carousel': 'Carousel',
    'sidenav.components.dataDisplay.card': 'Card',
    'sidenav.components.dataDisplay.calendar': 'Calendar',
    'sidenav.components.dataDisplay.descriptions': 'Descriptions',
    'sidenav.components.dataDisplay.empty': 'Empty',
    'sidenav.components.dataDisplay.list': 'List',
    'sidenav.components.dataDisplay.popover': 'Popover',
    'sidenav.components.dataDisplay.statistic': 'Statistic',
    'sidenav.components.dataDisplay.tree': 'Tree',
    'sidenav.components.dataDisplay.tooltip': 'Tooltip',
    'sidenav.components.dataDisplay.timeline': 'Timeline',
    'sidenav.components.dataDisplay.tag': 'Tag',
    'sidenav.components.dataDisplay.tabs': 'Tabs',
    'sidenav.components.dataDisplay.table': 'Table',

    'sidenav.components.feedback': 'Feedback',
    'sidenav.components.feedback.alert': 'Alert',
    'sidenav.components.feedback.drawer': 'Drawer',
    'sidenav.components.feedback.modal': 'Modal',
    'sidenav.components.feedback.message': 'Message',
    'sidenav.components.feedback.notification': 'Notification',
    'sidenav.components.feedback.progress': 'Progress',
    'sidenav.components.feedback.popconfirm': 'Popconfirm',
    'sidenav.components.feedback.result': 'Result',
    'sidenav.components.feedback.spin': 'Spin',
    'sidenav.components.feedback.skeleton': 'Skeleton',

    'sidenav.components.other': 'Other',
    'sidenav.components.other.anchor': 'Anchor',
    'sidenav.components.other.backtop': 'BackTop',
    'sidenav.components.other.configProvider': 'Config Provider',
    'sidenav.components.other.divider': 'Divider',

    'sidenav.charts': 'Charts',
    'sidenav.charts.apex': 'Apex Charts',
    'sidenav.charts.chartjs': 'ChartJs',

    'sidenav.dashboard.default': 'Default',
    'sidenav.dashboard.analytic': 'Analytic',
    'sidenav.dashboard.sales': 'Sales',

    'sidenav.maps': 'Maps',
    'sidenav.maps.google': 'Google Maps',
    'sidenav.maps.simple': 'Simple Maps',

    'sidenav.pages': 'Pages',
    'sidenav.pages.profile': 'Profile',
    'sidenav.pages.invoice': 'Invoice',
    'sidenav.pages.pricing': 'Pricing',
    'sidenav.pages.faq': 'FAQ',
    'sidenav.pages.setting': 'Setting',
    'sidenav.pages.userlist': 'User list',

    'sidenav.authentication': 'Authentication',
    'sidenav.authentication.login.1': 'Login 1',
    'sidenav.authentication.login.2': 'Login 2',
    'sidenav.authentication.register.1': 'Register 1',
    'sidenav.authentication.register.2': 'Register 2',
    'sidenav.authentication.forgetPassword': 'Forget Password',

    'sidenav.errors': 'Errors',
    'sidenav.errors.error.1': 'Error Page 1',
    'sidenav.errors.error.2': 'Error Page 2',

    'sidenav.docs': 'Docs',
    'sidenav.docs.documentation': 'Documentation',
    'sidenav.docs.changelog': 'Changelog',
  },
};
export default en;
