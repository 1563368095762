import antdJaJP from 'antd/es/locale/ja_JP';

const ja = {
  antd: antdJaJP,
  locale: 'ja-JP',
  messages: {
    Home: 'ホームページ',
    Overview: '概要',
    Activities: 'スクラムボード',
    Calendar: 'カレンダー',
    Projects: 'プロジェクト',
    Reports: 'レポート',
    Support: 'サポート',

    'sidenav.dashboard': 'ダッシュボード',
    'sidenav.dashboard.default': '既定',
    'sidenav.dashboard.analytic': '分析',
    'sidenav.dashboard.sales': '売り上げ',

    'sidenav.apps': '応用',
    'sidenav.apps.mail': '郵便物',
    'sidenav.apps.chat': 'チャット',
    'sidenav.apps.calendar': 'カレンダー',
    'sidenav.apps.project': '事業',
    'sidenav.apps.project.list': 'リスト',
    'sidenav.apps.project.scrumboard': 'スクラムボード',
    'sidenav.apps.ecommerce': '電子商取引',
    'sidenav.apps.ecommerce.product': '製品',
    'sidenav.apps.ecommerce.editProduct': '商品を編集',
    'sidenav.apps.ecommerce.addProduct': '製品を追加',
    'sidenav.apps.ecommerce.productList': '製品リスト',
    'sidenav.apps.ecommerce.orders': '注文',

    'sidenav.components': '部品',
    'sidenav.components.general': '一般的な',
    'sidenav.components.general.button': 'ボタン',
    'sidenav.components.general.icon': 'アイコン',
    'sidenav.components.general.typography': '活版',
    'sidenav.components.layout': '割付',
    'sidenav.components.layout.grid': 'グリッド',
    'sidenav.components.layout.layout': '割付',

    'sidenav.components.navigation': '航法',
    'sidenav.components.navigation.affix': 'ネイル',
    'sidenav.components.navigation.breadcrumb': '面包屑',
    'sidenav.components.navigation.dropdown': 'ドロップダウン選択',
    'sidenav.components.navigation.menu': 'メニュー',
    'sidenav.components.navigation.pagination': 'ページネーション',
    'sidenav.components.navigation.pageHeader': 'ページヘッダー',
    'sidenav.components.navigation.steps': '階段',

    'sidenav.components.dataEntry': 'データ入力',
    'sidenav.components.dataEntry.autoComplete': 'オートコンプリート',
    'sidenav.components.dataEntry.checkbox': 'チェックボックス',
    'sidenav.components.dataEntry.cascader': 'カスケード',
    'sidenav.components.dataEntry.datePicker': '日付ピッカー',
    'sidenav.components.dataEntry.form': '願書',
    'sidenav.components.dataEntry.inputNumber': '入力番号',
    'sidenav.components.dataEntry.input': '入力',
    'sidenav.components.dataEntry.mentions': '言及',
    'sidenav.components.dataEntry.rate': '割合',
    'sidenav.components.dataEntry.radio': 'ラジオボックス',
    'sidenav.components.dataEntry.switch': 'スイッチ',
    'sidenav.components.dataEntry.slider': 'スライド入力バー',
    'sidenav.components.dataEntry.select': 'セレクター',
    'sidenav.components.dataEntry.treeSelect': 'ツリー選択',
    'sidenav.components.dataEntry.transfer': 'シャトルボックス',
    'sidenav.components.dataEntry.timePicker': '時間選択ボックス',
    'sidenav.components.dataEntry.upload': 'アップロード',

    'sidenav.components.dataDisplay': 'データ表示',
    'sidenav.components.dataDisplay.avatar': 'アバター',
    'sidenav.components.dataDisplay.badge': 'ロゴの数',
    'sidenav.components.dataDisplay.comment': 'レビュー',
    'sidenav.components.dataDisplay.collapse': '折りたたみパネル',
    'sidenav.components.dataDisplay.carousel': 'マーキー',
    'sidenav.components.dataDisplay.card': 'カード',
    'sidenav.components.dataDisplay.calendar': 'カレンダー',
    'sidenav.components.dataDisplay.descriptions': '説明リスト',
    'sidenav.components.dataDisplay.empty': '空の状態',
    'sidenav.components.dataDisplay.list': '一覧',
    'sidenav.components.dataDisplay.popover': 'バブルカード',
    'sidenav.components.dataDisplay.statistic': '統計値',
    'sidenav.components.dataDisplay.tree': 'ツリー制御',
    'sidenav.components.dataDisplay.tooltip': 'ツールチップ',
    'sidenav.components.dataDisplay.timeline': 'タイムライン',
    'sidenav.components.dataDisplay.tag': '付箋',
    'sidenav.components.dataDisplay.tabs': 'タブ',
    'sidenav.components.dataDisplay.table': 'テーブル',

    'sidenav.components.feedback': '饋還',
    'sidenav.components.feedback.alert': '警告通知',
    'sidenav.components.feedback.drawer': '引き出し',
    'sidenav.components.feedback.modal': 'モーダル',
    'sidenav.components.feedback.message': '言付け',
    'sidenav.components.feedback.notification': '通知',
    'sidenav.components.feedback.progress': '進展',
    'sidenav.components.feedback.popconfirm': 'バブル確認ボックス',
    'sidenav.components.feedback.result': '結果',
    'sidenav.components.feedback.spin': '読み込み中',
    'sidenav.components.feedback.skeleton': 'スケルトンスクリーン',

    'sidenav.components.other': 'その他',
    'sidenav.components.other.anchor': 'アンカー',
    'sidenav.components.other.backtop': '上に戻る',
    'sidenav.components.other.configProvider': 'グローバル構成',
    'sidenav.components.other.divider': '分割線',

    'sidenav.charts': 'チャート',
    'sidenav.charts.apex': 'Apex Charts',
    'sidenav.charts.chartjs': 'ChartJs',

    'sidenav.maps': '地図',
    'sidenav.maps.google': 'Google Maps',
    'sidenav.maps.simple': 'Simple Maps',

    'sidenav.pages': 'ページ',
    'sidenav.pages.profile': 'プロフィール',
    'sidenav.pages.invoice': 'インヴォイス',
    'sidenav.pages.pricing': '値段付け',
    'sidenav.pages.faq': 'よくある質問',
    'sidenav.pages.setting': 'セッティング',
    'sidenav.pages.userlist': 'ユーザーリスト',

    'sidenav.authentication': '認証',
    'sidenav.authentication.login.1': 'ログイン 1',
    'sidenav.authentication.login.2': 'ログイン 2',
    'sidenav.authentication.register.1': '登録 1',
    'sidenav.authentication.register.2': '登録 2',
    'sidenav.authentication.forgetPassword': 'パスワードを忘れた',

    'sidenav.errors': 'エラー',
    'sidenav.errors.error.1': 'エラーページ 1',
    'sidenav.errors.error.2': 'エラーページ 2',

    'sidenav.docs': '文書',
    'sidenav.docs.documentation': 'ドキュメンテーション',
    'sidenav.docs.changelog': '変更ログ',
  },
};
export default ja;
