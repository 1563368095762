import antdFrFR from 'antd/es/locale/fr_FR';

const fr = {
  antd: antdFrFR,
  locale: 'fr-FR',
  messages: {
    home: 'Accueil',
    Overview: "Vue d'ensemble",
    Activities: 'Activités',
    Calendar: 'Calendrier',
    Projects: 'Projets',
    Reports: 'Rapports',
    Support: 'Support',

    'sidenav.dashboard': 'Tableau de bord',
    'sidenav.dashboard.default': 'Défaut',
    'sidenav.dashboard.analytic': 'Analytique',
    'sidenav.dashboard.sales': 'Ventes',

    'sidenav.apps': 'Applications',
    'sidenav.apps.mail': 'Courrier',
    'sidenav.apps.chat': 'Bavarder',
    'sidenav.apps.calendar': 'Calendrier',
    'sidenav.apps.project': 'Projet',
    'sidenav.apps.project.list': 'Liste',
    'sidenav.apps.project.scrumboard': 'Planche de mêlée',
    'sidenav.apps.ecommerce': 'E-Commerce',
    'sidenav.apps.ecommerce.product': 'Produit',
    'sidenav.apps.ecommerce.editProduct': 'Modifier le produit',
    'sidenav.apps.ecommerce.addProduct': 'Ajouter un produit',
    'sidenav.apps.ecommerce.productList': 'Liste de produits',
    'sidenav.apps.ecommerce.orders': 'Orders',

    'sidenav.components': 'Composants',
    'sidenav.components.general': 'Général',
    'sidenav.components.general.button': 'Bouton',
    'sidenav.components.general.icon': 'Icône',
    'sidenav.components.general.typography': 'Typographie',
    'sidenav.components.layout': 'Disposition',
    'sidenav.components.layout.grid': 'La grille',
    'sidenav.components.layout.layout': 'Disposition',

    'sidenav.components.navigation': 'La navigation',
    'sidenav.components.navigation.affix': 'Affixe',
    'sidenav.components.navigation.breadcrumb': 'Miette de pain',
    'sidenav.components.navigation.dropdown': 'Menu déroulant',
    'sidenav.components.navigation.menu': 'Menu',
    'sidenav.components.navigation.pagination': 'Pagination',
    'sidenav.components.navigation.pageHeader': 'En-tête de page',
    'sidenav.components.navigation.steps': 'Pas',

    'sidenav.components.dataEntry': 'La saisie des données',
    'sidenav.components.dataEntry.autoComplete': 'Saisie automatique',
    'sidenav.components.dataEntry.checkbox': 'Case à cocher',
    'sidenav.components.dataEntry.cascader': 'Cascader',
    'sidenav.components.dataEntry.datePicker': 'Sélecteur de date',
    'sidenav.components.dataEntry.form': 'Forme',
    'sidenav.components.dataEntry.inputNumber': "Numéro d'entrée",
    'sidenav.components.dataEntry.input': 'Champ de saisie',
    'sidenav.components.dataEntry.mentions': 'La mention',
    'sidenav.components.dataEntry.rate': 'Taux',
    'sidenav.components.dataEntry.radio': 'Bouton radio',
    'sidenav.components.dataEntry.switch': 'Commutateur',
    'sidenav.components.dataEntry.slider': 'Glissière',
    'sidenav.components.dataEntry.select': 'Sélecteur',
    'sidenav.components.dataEntry.treeSelect': "Sélecteur d'arbre",
    'sidenav.components.dataEntry.transfer': 'Transfert',
    'sidenav.components.dataEntry.timePicker': 'Sélecteur de temps',
    'sidenav.components.dataEntry.upload': 'Télécharger',

    'sidenav.components.dataDisplay': 'Affichage des données',
    'sidenav.components.dataDisplay.avatar': 'Avatar',
    'sidenav.components.dataDisplay.badge': 'Le badge',
    'sidenav.components.dataDisplay.comment': 'Commentaire',
    'sidenav.components.dataDisplay.collapse': 'Effondrer',
    'sidenav.components.dataDisplay.carousel': 'Carrousel',
    'sidenav.components.dataDisplay.card': 'Carte',
    'sidenav.components.dataDisplay.calendar': 'Calendrier',
    'sidenav.components.dataDisplay.descriptions': 'Descriptions',
    'sidenav.components.dataDisplay.empty': 'Vide',
    'sidenav.components.dataDisplay.list': 'Liste',
    'sidenav.components.dataDisplay.popover': 'Popover',
    'sidenav.components.dataDisplay.statistic': 'Statistique',
    'sidenav.components.dataDisplay.tree': 'Arbre',
    'sidenav.components.dataDisplay.tooltip': 'Info-bulle',
    'sidenav.components.dataDisplay.timeline': 'Chronologie',
    'sidenav.components.dataDisplay.tag': 'Étiquette',
    'sidenav.components.dataDisplay.tabs': 'Onglets',
    'sidenav.components.dataDisplay.table': 'Table',

    'sidenav.components.feedback': "Retour d'information",
    'sidenav.components.feedback.alert': 'Alerte',
    'sidenav.components.feedback.drawer': 'Tiroir',
    'sidenav.components.feedback.modal': 'Modal',
    'sidenav.components.feedback.message': 'Message',
    'sidenav.components.feedback.notification': 'Notification',
    'sidenav.components.feedback.progress': 'Le progrès',
    'sidenav.components.feedback.popconfirm': 'Confirmation de la pop',
    'sidenav.components.feedback.result': 'Résultat',
    'sidenav.components.feedback.spin': 'Tourner',
    'sidenav.components.feedback.skeleton': 'Squelette',

    'sidenav.components.other': 'Autre',
    'sidenav.components.other.anchor': 'Ancre',
    'sidenav.components.other.backtop': 'Retour au sommet',
    'sidenav.components.other.configProvider': 'Fournisseur de configuration',
    'sidenav.components.other.divider': 'Séparateur',

    'sidenav.charts': 'Graphiques',
    'sidenav.charts.apex': 'Apex Charts',
    'sidenav.charts.chartjs': 'ChartJs',

    'sidenav.maps': 'Plans',
    'sidenav.maps.google': 'Google Maps',
    'sidenav.maps.simple': 'Simple Maps',

    'sidenav.pages': 'Pages',
    'sidenav.pages.profile': 'Profil',
    'sidenav.pages.invoice': "Facture d'achat",
    'sidenav.pages.pricing': 'Tarification',
    'sidenav.pages.faq': 'FAQ',
    'sidenav.pages.setting': 'Réglage',
    'sidenav.pages.userlist': "Liste d'utilisateur",

    'sidenav.authentication': 'Authentification',
    'sidenav.authentication.login.1': "S'identifier 1",
    'sidenav.authentication.login.2': "S'identifier 2",
    'sidenav.authentication.register.1': "S'inscrire 1",
    'sidenav.authentication.register.2': "S'inscrire 2",
    'sidenav.authentication.forgetPassword': 'Mot de passe oublié',

    'sidenav.errors': 'Les erreurs',
    'sidenav.errors.error.1': "Page d'erreur 1",
    'sidenav.errors.error.2': "Page d'erreur 2",

    'sidenav.docs': 'Docs',
    'sidenav.docs.documentation': 'Documentation',
    'sidenav.docs.changelog': 'Changelog',
  },
};
export default fr;
