import AppRouter from './AppRouter';
import AppTheme from './AppTheme';
import React from 'react';
import { Provider } from 'react-redux';
import { store } from './data/store';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'data/msal';

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <AppTheme>
          <div className="App">
            <AppRouter />
          </div>
        </AppTheme>
      </Provider>
    </MsalProvider>
  );
}

export default App;
