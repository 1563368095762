import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './types';

export type Auth = {
  user: User;
  token: string;
};

export type LoginForm = {
  email: string;
  password: string;
};

export const emptyAuth: Auth = {
  user: {
    id: 0,
    email: '',
    phone: '',
    firstName: ' ',
    lastName: ' ',
  },
  token: '',
};

const json = localStorage.getItem('AUTH_STATE');
const initialState: Auth = json ? JSON.parse(json) : emptyAuth;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(_, a: PayloadAction<Auth>) {
      return a.payload;
    },
    clearAuth() {
      return emptyAuth;
    },
  },
});

export default authSlice.reducer;

export const { setAuth, clearAuth } = authSlice.actions;
