import { configureStore, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { message } from 'antd';
import api from './api';
import authReducer, { clearAuth } from './auth';
import themeReducer from './theme';

export const apiErrorLogger: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const errorPayload = action.payload;
    const errorMessage =
      errorPayload?.data?.error ?? 'An error occured. Please check your network connection';
    message.error(errorMessage);
    if (errorPayload?.status === 401) {
      api.dispatch(clearAuth());
    }
  }
  next(action);
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (m) => m().concat(api.middleware, apiErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
