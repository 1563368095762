import { ConfigProvider } from 'antd';
import { useTheme } from 'hooks/theme';
import locales from 'lang';
import React from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { IntlProvider } from 'react-intl';

const themes = {
  dark: `${process.env.PUBLIC_URL}/theme/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/theme/light-theme.css`,
};

export default function AppTheme(props: { children: React.ReactNode }) {
  const { currentTheme, currentLocale } = useTheme();
  const { locale, messages, antd } = locales[currentLocale];
  return (
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={currentTheme}>
      <IntlProvider locale={locale} messages={messages}>
        <ConfigProvider locale={antd} children={props.children} />
      </IntlProvider>
    </ThemeSwitcherProvider>
  );
}
