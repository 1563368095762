import React, { lazy, Suspense } from 'react';
import Loading from 'emilus/components/Loading';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading cover="page" />}>
        <Switch>
          <Route path="/login" component={lazy(() => import('./views/auth/Login'))} />
          <Route path="/register" component={lazy(() => import('./views/auth/Register'))} />
          <Route path="/forgot" component={lazy(() => import('./views/auth/Forgot'))} />
          <Route path="/" component={lazy(() => import('./views/Home'))} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
